/* Dashboard.css */

.dashboard{
  margin: 20px;
  height: calc(100vh - 40px);
}

.dashboard h1.ui.header{
  color: var(--primary-theme-color);
}

.dashboard .filter-row{
  display: flex; 
  flex-wrap: wrap;
}

.dashboard .filter-row .ui.button{
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
}

.dashboard .filter-row .patient-search .ui.input{
  flex: 1
}

.dashboard .filter-row .patient-search{
  display: flex;
  flex-grow: 1
}

.dashboard .filter-row>div{
  margin: 10px;
  margin-left: 0px;
}

.dashboard .filter-row .ui.button{
  margin-left: 10px;
}

.dashboard .filter-row b{
  align-self: center; 
  margin: 10px;
}

.dashboard .filter-row .search-input-wrapper{
  margin: 10px; 
  flex: 1;
}

.dashboard .filter-row .search-input-wrapper .ui.input{
  width: 100%;
}

.dashboard .dashboard-table-wrapper table{
  width: 100%;
}

.dashboard .dashboard-table-wrapper thead{
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
  text-align: center;
  font-weight: bold;
}

.dashboard .dashboard-table-wrapper .rt-resizable-header-content .ui.label{
  padding: 6px 15% 6px;
  border-radius: 100px;
  background-color: var(--dashboard-label-bg);
  color: white;
  margin: 5px;
}

.dashboard .ReactTable{
  height: 490px;
  /* border-top-left-radius: 15px;
  border-top-right-radius: 15px; */
}

.dashboard .ReactTable .rt-thead.-header,
.dashboard .ReactTable .rt-table{
  /* border-top-left-radius: 15px;
  border-top-right-radius: 15px; */
}

.dashboard .hn-name-cell .hn{
  color: var(--primary-theme-color);
}

.dashboard .payment-label.not-paid{
    color: var(--primary-err-color);
}

.dashboard .ReactTable .-even{
  background-color: var(--hover-bg);
}

.dashboard .ReactTable .-odd{
  background-color: var(--light-hover-bg);
}

.dashboard .ReactTable .rt-tr-group{
  text-align: center;
  font-weight: bold;
}

.dashboard .ReactTable .row-label{
  padding: 6px 15% 6px;
  line-height: initial;
  border-radius: 100px;
  word-break: break-all;
}

.dashboard .ReactTable .row-label.current{
  background-color: var(--primary-correct-color);
  color: white;
}

.dashboard .ReactTable .row-label h4{
  margin: 0;
}

.dashboard .division-wrapper{
  flex: 0.5;
}