.facebookLoginButton{
  background-color: #5890FF;
  color: #FFFFFF;
  display: inline-block;
  font-weight: bold;
  padding: 0;
  cursor: pointer;
}

.facebookLoginButton img{
  content: url(facebook.png);
  vertical-align: middle;
}

.facebookLoginButton span{
  vertical-align: middle;
}