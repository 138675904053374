/* CardLayout.css react-lib */

.MainLayout .middle .CardLayout {
    margin-bottom: 1em;
    margin-top: 1em;
}

.MainLayout .middle .CardLayout.noCardMargin,
.noCardMargin {
    margin-top: 0%;
}
