.lineLoginButton{
  background-color: #00C300;
  color: #FFFFFF;
  display: inline-block;
  font-weight: bold;
  padding: 0;
  cursor: pointer;
}

.lineLoginButton:hover{
  background-color: #00E000;
}


.lineLoginButton img{
  content: url(line.png);
  vertical-align: middle;
}

.lineLoginButton:hover img{
  content: url(line_hover.png);
}

.lineLoginButton span{
  vertical-align: middle;
}