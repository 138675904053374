.ReactTable .rt-thead.-header {
  background-color: var(--primary-theme-color);
  font-weight: bold;
  color: var(--primary-font-color, black);
}

.ReactTable .rt-tr.selected {
  background-color: var(--primary-theme-color-half) !important;
  color: black !important;
}
