.Login{
  display: flex;
  height: 93vh;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.Login .fbLogin{
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 285px;
}

.Login .pwdLogin {
  text-align: left;
  border-left: solid 1px #dcdcdc;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  justify-content: center;

  form {
    margin: 2em 4em;
    max-width: 400px;
    min-width: 285px;

    .button {
      width: 100%;
    }
  }
}