:root{
  --primary-err-color: #FF2929;
  --std-border: 1px solid;
  --hover-bg: rgba(0, 0, 0, 0.1);
  --secondary-bg-color: #F2F2F2;
  --disable-bg-color: #606060;
  --primary-correct-color: #27AE60;
} 

html[data-theme="penta"]{
  --primary-theme-color: rgba(93,188,210);
  --primary-theme-color-half: rgba(93,188,210, 0.5);
  --primary-font-color: white;
  --hover-bg: rgba(93,188,210, 0.2);
  --light-bg-color: rgba(93,188,210, 0.3);
  --dark-bg-color: #004884;
  --dark-bg-color-07: rgba(0,42,132, 0.7);
  --std-border: 0;
  --chat-bubble-color: rgba(1, 157, 154, 0.2);
  --chat-bubble-color-selected: rgba(1, 157, 154, 0.6);
  --dashboard-label-bg: rgba(234,246,252, 0.5);
}

html[data-theme="penguin"]{
  --primary-theme-color: rgba(35,153,229);
  --primary-theme-color-half: rgba(35,153,229, 0.5);
  --primary-font-color: white;
  --hover-bg: rgba(35,153,229, 0.2);
  --light-bg-color: rgba(35,153,229, 0.3);
  --dark-bg-color: rgba(35,153,229);
  --dark-bg-color-07: rgba(35,153,229, 0.7);
  --chat-bubble-color: rgba(35,153,229, 0.2);
  --chat-bubble-color-selected: rgba(35,153,229, 0.4);
  --primary-err-color: #FF4D4D;
  --std-border: 0;
  --light-hover-bg: rgba(35,153,229, 0.1);
  --primary-correct-color: #27AE60;
  --dashboard-label-bg: rgba(234,246,252, 0.5);
}

.header5{
  font-size: 1rem;
}

.main-bil {
  /* height: 100vh; */
}

button.ui.icon.button.tabButton{
  width: 100%; 
  margin: 0; 
  border-radius: 0;
  background-color: var(--light-bg-color);
  color: black;
}

button.ui.active.icon.button.tabButton{
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
}

button.ui.icon.button.tabButton:hover:not(.active){
  background-color: var(--primary-theme-color-half);
}

.MainScreen .ui.grid .page{
  padding: 0; 
  width: calc(100% - 40px);
  /* height: calc(100vh - 40px); */
}

.MainScreen > .ui.grid{
  margin: 0;
  padding: 0; 
  overflow: hidden;
  /* height: 93vh; */
}

.MainScreen .ui.grid .tab{
  padding: 0; 
  width: 40px; 
  border-right: 0.5px solid var(--primary-theme-color);
  min-height: calc(100vh - 40px);
  max-height: 100vh;
}


.MainScreen .ui.secondary.menu{
  background-color: var(--primary-theme-color); 
  margin: 0; 
  height: 40px;
  border-bottom: var(--std-border) 
}

.MainScreen .ui.secondary.menu h2{
  color: var(--primary-font-color); 
  cursor: pointer;
}

.MainScreen .ui.menu .right.item h3{
  margin: 0;
  color: var(--primary-font-color);
}

.MainScreen .ui.secondary.menu .ui.button{
  background-color: var(--primary-theme-color); 
  color: var(--primary-font-color);
}

.Login .email-login-button{
  margin: 0 auto;
  height: 40px;
  max-width: 284px;
  line-height: 38px;
  cursor: pointer;
  color: var(--primary-theme-color);
  border: 2px solid var(--primary-theme-color, black);
  background-color: transparent;
}