/* ModInfo.css react-lib */

.modHeader{
  font-weight: bold;
}

.ui.segment.modContent{
  padding-top: 0;
  border: 0;
  margin: 0;
  overflow-wrap: break-word;
  line-height: 1.3em;
}

button.ui.button.approveButton{
  margin-top: 0.5em;
}